import { render, staticRenderFns } from "./RestoreBrand.vue?vue&type=template&id=741cc405&"
import script from "./RestoreBrand.vue?vue&type=script&lang=js&"
export * from "./RestoreBrand.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports